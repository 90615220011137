















































import Vue from 'vue'
import ExchangeService from '@/services/exchange'
import { ExchangeRefInstrument } from '@/services/exchange/models'

interface SupportedExchange {
  exchange: string
  productType: string[]
  // productTypes: string[]
  smartTrade: boolean
  volume: number
  tradingPairs: number
}

export default Vue.extend({
  data(): {
    isLoading: boolean
    supportedExchanges: SupportedExchange[]
    productType: string[]
    productTypeSelected: string[]
  } {
    return {
      isLoading: false,
      supportedExchanges: [],
      productType: [],
      productTypeSelected: []
    }
  },

  computed: {
    supportedExchangesFiltered(): SupportedExchange[] {
      if (this.productTypeSelected.length > 0) {
        return this.supportedExchanges.filter((exchange) =>
          this.productTypeSelected.some((type) => exchange.productType.includes(type))
        )
      }

      return this.supportedExchanges
    }
  },

  methods: {
    async getExchangeRefInstrument() {
      this.isLoading = true
      try {
        const { data: eRefInstrumentRes } = await ExchangeService.getExchangeRefInstrument({
          'field[0]': 'exchange',
          'field[1]': 'productType',
          'field[2]': 'symbol',
          'field[3]': 'baseCurrency',
          'field[4]': 'quoteCurrency'
        })

        if (eRefInstrumentRes.success && eRefInstrumentRes.data) {
          this.supportedExchanges = eRefInstrumentRes.data.reduce(
            (acc: SupportedExchange[], val: ExchangeRefInstrument) => {
              //  get table data
              const index = acc.findIndex((item: SupportedExchange) => item.exchange === val.exchange)
              if (index < 0) {
                acc.push({
                  exchange: val.exchange,
                  // productType: ['SPOT'],
                  productType: [val.productType],
                  smartTrade: true,
                  volume: 0,
                  tradingPairs: 1
                })
              } else {
                const newData = {
                  ...acc[index],
                  productType: acc[index].productType.includes(val.productType)
                    ? acc[index].productType
                    : [...acc[index].productType, val.productType],
                  tradingPairs: acc[index].tradingPairs + 1
                }

                acc.splice(index, 1, newData)
              }

              // get order types
              if (!this.productType.includes(val.productType)) {
                this.productType.push(val.productType)
              }

              return acc
            },
            []
          )
        }
      } finally {
        this.isLoading = false
      }
    }
  },

  created() {
    this.getExchangeRefInstrument()
  }
})
